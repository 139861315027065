import { environment } from './../environments/environment';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EnvTypeConfig } from 'src/environments/env-type-config';
import { NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(private router: Router) {}
}
