  <div class="ln-bg" style="background-image: url('assets/img/bg.png');"></div>
  
  <div class="ln-wrapper">

    <div class="ln-header">
      <a href="https://www.lexis360intelligence.fr/" class="ln-logo" title="Lexis 360 Intelligence">
        <svg>
          <use href="assets/img/spritesvg.svg#logolexis360"></use>
        </svg>
      </a>

    </div><!-- .ln-header -->

    <div class="ln-middle">

      <div class="ln-illu">
        <img src="assets/img/illu.png" alt="">
      </div><!-- .ln-illu -->

      <div class="ln-content">
        <h1>
          Bienvenue sur la nouvelle version<br>
          <strong>Lexis<sup>®</sup> 360 Intelligence</strong>
        </h1>
        <div class="ln-content-i">
          <div class="ln-illu-2">
            <img src="assets/img/illu-2.png" alt="">
          </div><!-- .ln-illu-2 -->
          <div class="ln-desc">
            <p>
              L’ancienne version Lexis® 360 <strong>a pris fin le 30 juin 2023</strong> et l'url que vous utilisiez
              n'est plus disponible.<br>
              Ajoutez dès maintenant <a
                href="https://www.lexis360intelligence.fr">https://www.lexis360intelligence.fr</a> à vos favoris pour
              continuer à profiter de vos contenus préférés.
            </p>
            <p><a href="https://www.lexis360intelligence.fr" class="btn-primary">Continuer vers Lexis® 360
                Intelligence</a></p>
          </div><!-- .ln-desc -->
        </div><!-- .ln-content-i -->
      </div><!-- .ln-content -->

    </div><!-- .ln-middle -->

    <div class="ln-footer">

      <ul class="ln-footer-list">
        <li class="ln-footer-item">
          <a class="ln-footer-link" href="https://www.lexisnexis.fr/a-propos-de/lexisnexis-en-france">A propos de
            LexisNexis</a>
        </li>
        <li class="ln-footer-item">
          <a class="ln-footer-link" href="https://www.lexis360intelligence.fr/conditions-generales">Conditions générales
            d'utilisation</a>
        </li>
        <li class="ln-footer-item">
          <a class="ln-footer-link" href="http://www.lexisnexis.fr/infos_societe/infos_legales.html">Mentions légales</a>
        </li>
        <li class="ln-footer-item">
          <a class="ln-footer-link" href="https://www.lexisnexis.fr/politique-confidentialite">Politique de
            Confidentialité et données personnelles</a>
        </li>
        <li class="ln-footer-item">
          <a class="ln-footer-link" href="https://www.lexisnexis.com/systems/privacy-cookies/default.aspx?gid=2586&locale=fr_fr">Politique Cookies</a>
        </li>
        <li class="ln-footer-item">
          <a class="ln-footer-link" href="https://www.lexis360intelligence.fr/mention-legales" target="_blank">Copyright ©
            2023 LexisNexis.</a>
        </li>
      </ul>

      <a class="ln-footer-logo-relx" href="https://www.relx.com/" target="_blank" aria-label="RELX">
        <svg>
          <use href="assets/img/spritesvg.svg#logorelx"></use>
        </svg>
      </a>

    </div><!-- .ln-footer -->

  </div><!-- ln-wrapper -->
